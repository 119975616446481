import Dashboard from './dashboard';
import Cta from './cta';
import MediaLibrary from './media-library';
import ControlloSalvataggio from './controllo-salvataggio';

document.addEventListener('DOMContentLoaded', function(){
	Dashboard();
	Cta();
	MediaLibrary();
	ControlloSalvataggio(); 
});
