/**
 * Funzione utilizzata per salvare l'attuale ordinamento su SessionStorage.
 *
 * @param {string} keyName  nome della chiave.
 * @param {string} keyValue contiene il valore da memorizzare.
 * @returns {void}
 */
const storeSortKey = (keyName, keyValue) => {
	sessionStorage.setItem(keyName, keyValue);
};

/**
 * Funzione utilizzata per ripristinare un eventuale ordinamento già presente su SessionStorage.
 *
 * @param {string} keyName nome della chiave.
 * @returns {void}
 */
const restoreAnySort = (keyName) => {
	if (sessionStorage.getItem(keyName)) {
		const data = sessionStorage.getItem(keyName).split('|');
		document.getElementById(data[0]).click();
		if (data[1] === 'descending') {
			document.getElementById(data[0]).click();
		}
	}
};

/**
 * Funzione utilizzata per la gestione dell'ordinamento nei listati Topic e Campagne.
 *
 * @param {string} type Il tipo richiesto.
 * @returns {void}
 */
const sortTableInit = (type = 'topic') => {

	// Tipi di ordinamento gestiti.
	const typesManaged = ['topic', 'campaigns'];
	if (!typesManaged.includes(type)) {
		return;
	}
	const tableElement = document.getElementById(type);
	if (!tableElement) {
		return;
	}
	new Tablesort(tableElement);

	let key = 'topic-sort';
	if (type === 'campaigns') {
		key = 'campaigns-sort';
	}

	const tableIndexKey = document.querySelectorAll('.sortable-th');
	if (tableIndexKey.length > 0) {
		restoreAnySort(key);
		tableIndexKey.forEach((th) => {
			th.addEventListener('click', () => storeSortKey(key, `${th.id}|${th.ariaSort}`));
		});
	}
};

export default sortTableInit;
