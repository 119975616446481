/**
 * Funzione che inizializza i tooltip.
 *
 * @returns void.
 */
export default function initTooltips() {
	const tooltippedElements = document.querySelectorAll('.tooltipped');
	tooltippedElements.forEach((el) => {
		M.Tooltip.init(el);
	});
};
