/**
 * Implementa la tecnica di "debouncing" per ritardare l'esecuzione di una funzione.
 *
 * Questa funzione prende in input un'altra funzione (func) e un ritardo (delay) espresso in millisecondi.
 * Quando la funzione ritardata viene chiamata, viene attivato un timer. Se la funzione viene chiamata nuovamente
 * durante il periodo di ritardo, il timer viene cancellato e ne viene creato uno nuovo. La funzione ritardata
 * verrà quindi eseguita solo dopo che non ci saranno state ulteriori chiamate alla funzione per un certo periodo
 * di tempo pari a delay.
 *
 * @param {function} func - La funzione da ritardare.
 * @param {number} delay - L'intervallo di tempo in millisecondi entro cui evitare ulteriori chiamate.
 * @returns {function} - Una funzione ritardata che può essere utilizzata al posto della funzione originale.
 */
export default function debounce(func, delay) {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}
