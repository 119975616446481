import debounce from './helper';
import Handlebars from './handlebars';
import initTooltips from './tooltip';

let searchType;
let searchField;
let tbodyElement;

const searchData = debounce((value) => {
	const endpoint = `${window.location.origin}/${searchType}/search?s=${value}`;
	fetch(endpoint)
		.then((response) => {
			if (response.status !== 200) {
				M.toast({ html: 'Nessun risultato trovato' });
				return '';
			}
			return response.json();
		})
		.then((results) => {
			if (!results.data || results.data.length === 0) {
				M.toast({ html: 'Nessun risultato trovato' });
				tbodyElement.innerHTML = '';
				return;
			}

			const compiledTemplate = Handlebars.getTemplate(`${searchType}_search`);
			const renderedHTML = compiledTemplate(results);

			tbodyElement.innerHTML = renderedHTML;

			initTooltips();
		})
		// eslint-disable-next-line no-console
		.catch((error) => console.error('Errore:', error));
}, 500);

const resetSearch = () => {
	searchData('');
	searchField.value = '';
};

/**
 * Funzione principale per la gestione della ricerca per le pagine listato Topic e Campagne.
 *
 * @param {string} type Il tipo richiesto.
 * @returns {void}
 */
const initSearchData = (type = 'topic') => {
	// Tipi di ricerca gestiti.
	const typesManaged = ['topic', 'campaigns'];
	if (!typesManaged.includes(type)) {
		return;
	}
	searchType = type;

	const tableElement = document.getElementById(type);
	if (!tableElement) {
		return;
	}
	tbodyElement = tableElement.querySelector('tbody');

	const closeBtnSearchbar = document.querySelector('.close-searchbar');
	if (closeBtnSearchbar) {
		closeBtnSearchbar.addEventListener('click', resetSearch);
	}

	searchField = document.querySelector('#search');
	if (searchField) {
		searchField.addEventListener('keyup', (event) => searchData(event.target.value));
	}
};

export default initSearchData;
