import sendXhrRequest from './request';
import initSearchData from './search';
import Handlebars from './handlebars';
import sortTableInit from './sort-table';

/* global LANG:readonly */

let topicTableBody;
const urlBase = window.location.href;

const initDashboard = () => {
	sortTableInit('topic');

	$('.arrow-down').click(function(){
	    $('#search-bar').slideToggle();
	});
	
	const aggiungiTopic = document.querySelector('#aggiungi_topic');
	if ( aggiungiTopic ) {
		aggiungiTopic.addEventListener('click',addTopic);
	}
	topicTableBody = document.querySelector('#topic tbody');

	initSearchData('topic');
}

const addTopic = (event) =>  {
	const time = Date.now();
	const newTopic = LANG.new_topic;
	const compiledTemplate = Handlebars.getTemplate('aggiungi_topic');
	const html = compiledTemplate({ time, newTopic });
	topicTableBody.innerHTML = html + topicTableBody.innerHTML;
	M.updateTextFields();
}

export default initDashboard;
