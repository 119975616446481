let buttonSave;
let formHasChanged = false;
let isSingleCta;

/**
 * Funzione per determinare se tutti i campi required sono stati compilati.
 *
 * @returns {boolean} - Booleano per attivare o meno salvataggio.
 */
const publicationListIsFullfilled = () => {
	if (isSingleCta) {
		const network = document.querySelector('input[x-model="form.publication_list.network"]');
		const fpa = document.querySelector('input[x-model="form.publication_list.fpa"]');

		return fpa?.checked || network?.checked;
	}

	const networkCampaign = document.querySelector('input[name="campagna[publication_list][fpa]"]');
	const fpaCampaign = document.querySelector('input[name="campagna[publication_list][network]"]');

	if (!networkCampaign || ! fpaCampaign) {
		return false;
	}

	const campaignsCtaNetwork = document.querySelectorAll('input[x-model="form.publication_list.network"]');
	const campaignsCtaFpa = document.querySelectorAll('input[x-model="form.publication_list.fpa"]');


	let requiredFields = [networkCampaign.checked || fpaCampaign.checked];

	//INFO: i due campi avranno sicuramente la stessa lunghezza.
	// Questo perché vanno a coppia sia su singole cta che sulla campagna.
	campaignsCtaNetwork.forEach((networkCheckbox, index) => {
		if (networkCheckbox.disabled) {
			return;
		}
		const fpaCheckbox = campaignsCtaFpa[index];
		requiredFields.push(fpaCheckbox.checked || networkCheckbox.checked);
	});

	if (requiredFields.includes(false)) {
		return false;
	}
	
	return true;
};

const controlloSalvataggioInit = (isSingle = false) => {
	const inputElements = document.querySelectorAll('main input');
	const selectElements = document.querySelectorAll('main select');
	const clickedElements = document.querySelectorAll(
		'main a:not(.chip.chip-topic), main button, main i,main input[type="checkbox"], main input[type="radio"], img.img-iconcina',
	);
	isSingleCta = isSingle;
	buttonSave = document.querySelector('.save-btn');
	for (let i = inputElements.length - 1; i >= 0; i--) {
		inputElements[i].addEventListener('keydown', inputChanged);
	}
	if (buttonSave) {
		buttonSave.addEventListener('click', removeBeforeUnload);
	}
	for (let i = clickedElements.length - 1; i >= 0; i--) {
		clickedElements[i].addEventListener('click', inputChanged);
	}
	for (let i = selectElements.length - 1; i >= 0; i--) {
		selectElements[i].addEventListener('change', inputChanged);
	}
};

const inputChanged = () => {
	if (!formHasChanged && buttonSave && publicationListIsFullfilled()) {
		formHasChanged = true;
		window.addEventListener('beforeunload', messageBeforeUnload);
		buttonSave.disabled = false;
	}

	if (!publicationListIsFullfilled()) {
		buttonSave.disabled = true;
		formHasChanged = false;
	}
};

const removeBeforeUnload = () => {
	window.removeEventListener('beforeunload', messageBeforeUnload);
};

const messageBeforeUnload = (e) => {
	e.preventDefault();
	e.returnValue = true;
};

export default controlloSalvataggioInit;
